import { AxiosInstance, AxiosResponse } from 'axios';
import {
  PublisherDto,
  FilterPublisherDto,
  UpdatePublisherDto,
  JSONDataDto, CreatePublisherDto,
} from '../dto/publisher.dto';

class PublisherService {
  public url = '/publishers';

  constructor(private readonly client: AxiosInstance) {}

  public async getPublishers(filter: FilterPublisherDto): Promise<AxiosResponse<PublisherDto[]>> {
    return await this.client.get(this.url, { params: filter });
  }

  public async createPublisher(publisher: CreatePublisherDto): Promise<AxiosResponse<PublisherDto[]>> {
    return await this.client.post(`${this.url}`, publisher);
  }

  public async updatePublisher(id: string, publisher: UpdatePublisherDto): Promise<AxiosResponse<PublisherDto[]>> {
    return await this.client.put(`${this.url}/${id}`, publisher);
  }

  public async pullPublishers(gamInstanceIds: string[]): Promise<AxiosResponse<void>> {
    return await this.client.post(`${this.url}/pull-from-gam`, gamInstanceIds);
  }

  public async exportPublishers(publisherIds: string[]): Promise<AxiosResponse<BlobPart>> {
    return await this.client.post(`${this.url}/export`, publisherIds);
  }

  public async generateJSONFile(publisherIds: string[]): Promise<AxiosResponse<JSONDataDto>> {
    return await this.client.post(`${this.url}/json/generate`, publisherIds);
  }

  public async pushJSONFile(publisherIds: string[]): Promise<void> {
    await this.client.post(`${this.url}/json/push`, publisherIds);
  }
}

export default PublisherService;
