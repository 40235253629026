import { action, computed, makeObservable, observable } from 'mobx';
import { PartnerFilterDto, PartnerDto } from '../dto/partner.dto';
import PartnerService from '../data-services/PartnerService';
import { PublisherDto, FilterPublisherDto, UpdatePublisherDto, CreatePublisherDto } from '../dto/publisher.dto';
import PublisherService from '../data-services/PublisherService';
import { GAMInstanceDto, GAMInstancesFilterDto } from '../dto/gamInstance.dto';
import GAMInstancesService from '../data-services/GAMInstancesService';
import { AxiosResponse } from "axios";
import _ from "lodash";

export class PublisherStore {
  @observable public isLoading = false;
  @observable public error = '';
  @observable public isPublisherLoading = false;
  @observable public isFirstPublisherLoading = true;
  @observable private _allPublishers: PublisherDto[] = [];
  @observable private _publishers: PublisherDto[] = [];
  @observable private _partners: PartnerDto[] = [];
  @observable private _gamInstances: GAMInstanceDto[] = [];

  constructor(
      private readonly publisherApi: PublisherService,
      private readonly partnerApi: PartnerService,
      private readonly gamInstanceApi: GAMInstancesService,
  ) {
    makeObservable(this);
  }

  @computed public get publishers (): PublisherDto[] {
    return this._publishers;
  }

  @computed public get partners (): PartnerDto[] {
    return this._partners;
  }

  @computed public get gamInstances (): GAMInstanceDto[] {
    return this._gamInstances;
  }

  @computed public get publisherGamId (): string[] {
    return this._publishers.map(x => x.childNetworkCode);
  }

  @computed public get childNetworkCodes (): any {
    const result = this._allPublishers.map(x => x?.childNetworkCode);

    return _.uniq(result.filter(n => n));
  }

  @action public setIsLoading = (value: boolean): void => { this.isLoading = value; };
  @action public setIsPublisherLoading = (value: boolean): void => { this.isPublisherLoading = value; };
  @action public setIsFirstPublisherLoading = (value: boolean): void => { this.isFirstPublisherLoading = value; };
  @action public setError = (error: string): void => { this.error = error; };
  @action public clearError = (): void => { this.error = ''; };
  @action public readonly setPublishers = (publishers: PublisherDto[]): void => { this._publishers = publishers; };
  @action public readonly setAllPublishers = (publishers: PublisherDto[]): void => { this._allPublishers = publishers; };
  @action public readonly setPartners = (partners: PartnerDto[]): void => { this._partners = partners; };
  @action public readonly setGAMInstances = (gamInstances: GAMInstanceDto[]): void => { this._gamInstances = gamInstances; };

  @action public getPublishers = async (
      filter: FilterPublisherDto = {}
  ): Promise<PublisherDto[] | undefined> => {
    try {
      this.setIsLoading(true);
      this.setIsPublisherLoading(true);
      const { data } = await this.publisherApi.getPublishers(filter);

      if (this.isFirstPublisherLoading) {
        this.setAllPublishers(data);
      }

      if (data) {
        this.setPublishers(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsPublisherLoading(false);
      this.setIsFirstPublisherLoading(false);
      this.setIsLoading(false);
    }
  };

  @action public createPublisher = async (
      publisher: CreatePublisherDto,
  ): Promise<void> => {
    try {
      this.setIsLoading(true);
      await this.publisherApi.createPublisher(publisher);
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsLoading(false);
    }
  };

  @action public updatePublisher = async (
      id: string,
      publisher: UpdatePublisherDto,
  ): Promise<void> => {
    try {
      this.setIsLoading(true);
      await this.publisherApi.updatePublisher(id, publisher);
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsLoading(false);
    }
  };

  @action public pullPublishers = async (
      gamInstanceIds: string[],
  ): Promise<void> => {
    try {
      this.setIsLoading(true);
      await this.publisherApi.pullPublishers(gamInstanceIds);
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsLoading(false);
    }
  };

  // eslint-disable-next-line max-len
  @action public exportPublishers = async (publisherIds: string[]): Promise<AxiosResponse<BlobPart>> => this.publisherApi.exportPublishers(publisherIds);

  @action public getPartners = async (
      filter?: PartnerFilterDto,
  ): Promise<PartnerDto[] | undefined> => {
    try {
      this.setIsLoading(true);
      const { data } = await this.partnerApi.getPartners(filter);

      if (data) {
        this.setPartners(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsLoading(false);
    }
  };

  @action public getGAMInstances = async (
      filter?: GAMInstancesFilterDto,
  ): Promise<GAMInstanceDto[] | undefined> => {
    try {
      this.setIsLoading(true);
      const { data } = await this.gamInstanceApi.getGAMInstance(filter);

      if (data) {
        this.setGAMInstances(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setIsLoading(false);
    }
  };
}
